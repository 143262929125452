import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={35.459}
      height={43.42}
      viewBox="0 0 35.459 43.42"
      {...props}
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill="#000000"
        stroke=""
      >
        <path
          d="M2405 5029 c-150 -15 -416 -67 -433 -84 -5 -4 24 -135 32 -142 1 -2
50 8 107 22 215 52 470 73 684 56 330 -27 582 -95 870 -236 381 -186 686 -455
921 -812 43 -65 79 -120 81 -122 4 -6 44 13 92 44 l41 25 -31 52 c-105 178
-253 362 -418 521 -373 356 -851 589 -1364 663 -134 19 -456 26 -582 13z"
        />
        <path
          d="M1715 4861 c-49 -20 -135 -60 -191 -88 -107 -53 -304 -173 -304 -184
0 -12 73 -119 80 -119 4 0 48 25 96 56 105 66 274 152 382 195 42 17 80 34 84
37 7 8 -41 142 -51 141 -3 0 -46 -17 -96 -38z"
        />
        <path
          d="M2450 4734 c-14 -2 -65 -9 -115 -15 -290 -36 -646 -171 -896 -339
-101 -68 -229 -168 -229 -179 1 -3 22 -30 49 -59 l48 -52 64 52 c275 222 637
376 1004 429 162 24 513 14 641 -17 19 -5 23 1 38 67 9 40 14 74 12 76 -2 2
-42 11 -87 19 -80 14 -475 28 -529 18z"
        />
        <path
          d="M3197 4589 c-10 -39 -16 -72 -15 -73 2 -1 55 -18 118 -40 271 -91
526 -246 734 -446 640 -613 813 -1544 436 -2339 -32 -67 -50 -115 -44 -120 5
-5 35 -23 67 -41 l57 -32 56 116 c258 544 284 1181 69 1732 -167 429 -448 775
-839 1035 -175 116 -464 251 -586 274 l-36 6 -17 -72z"
        />
        <path
          d="M0 4265 l0 -325 75 0 75 0 0 -1265 0 -1265 75 0 75 0 0 110 0 110
225 0 225 0 0 -376 c0 -253 -4 -389 -11 -415 -40 -133 -207 -197 -326 -123
-29 18 -59 49 -78 78 l-30 49 -3 215 -3 215 -52 -7 c-29 -3 -62 -6 -75 -6
l-24 0 4 -223 3 -223 33 -66 c41 -83 119 -158 200 -189 43 -18 77 -23 142 -23
75 -1 93 3 150 30 112 54 188 151 214 274 l11 57 89 -84 89 -84 -27 -27 -26
-28 63 -52 c232 -188 567 -360 871 -447 624 -178 1316 -99 1879 217 71 40 220
133 246 154 2 2 -6 18 -17 36 l-21 34 54 42 c29 23 57 42 61 42 5 0 18 -12 30
-27 l21 -27 59 51 c343 297 614 722 743 1165 72 246 95 420 96 703 0 297 -34
511 -121 774 -34 102 -100 267 -119 299 -4 6 -135 -58 -135 -65 0 -4 19 -51
41 -103 124 -285 182 -577 182 -905 1 -350 -35 -510 -174 -788 -130 -257 -332
-523 -530 -698 l-74 -64 72 80 c82 92 213 266 208 275 -2 4 -32 24 -66 45 -55
33 -63 36 -71 21 -27 -49 -145 -193 -227 -278 -174 -182 -354 -313 -583 -423
-766 -369 -1681 -221 -2285 369 l-73 70 -55 -54 -55 -54 23 -25 c12 -14 -16 8
-62 49 -46 41 -90 84 -97 95 -12 18 -14 235 -12 1386 l3 1364 73 3 72 3 0 324
0 325 -525 0 -525 0 0 -325z m300 0 l0 -175 -75 0 -75 0 0 175 0 175 75 0 75
0 0 -175z m300 0 l0 -175 -75 0 -75 0 0 175 0 175 75 0 75 0 0 -175z m300 0
l0 -175 -75 0 -75 0 0 175 0 175 75 0 75 0 0 -175z m-150 -600 l0 -275 -225 0
-225 0 0 275 0 275 225 0 225 0 0 -275z m0 -1155 l0 -730 -225 0 -225 0 0 730
0 730 225 0 225 0 0 -730z"
        />
        <path d="M452 2813 l3 -78 73 -3 72 -3 0 81 0 80 -75 0 -76 0 3 -77z" />
        <path d="M450 2510 l0 -80 75 0 75 0 0 80 0 80 -75 0 -75 0 0 -80z" />
        <path d="M450 2205 l0 -75 75 0 75 0 0 75 0 75 -75 0 -75 0 0 -75z" />
        <path
          d="M3170 3980 c0 -80 -3 -112 -12 -114 -7 -3 -44 -14 -83 -25 -38 -11
-97 -36 -129 -56 l-60 -36 -81 81 -80 80 -53 -52 -52 -53 81 -81 81 -81 -27
-43 c-35 -55 -59 -113 -74 -177 l-13 -53 -114 0 -114 0 0 -75 0 -75 113 0 114
0 13 -57 c7 -31 32 -95 56 -142 l44 -85 -80 -81 -80 -80 52 -53 53 -52 80 80
81 80 85 -44 c47 -24 111 -49 142 -56 l57 -13 0 -114 0 -113 75 0 75 0 0 114
0 114 53 13 c64 15 122 39 177 74 l43 27 81 -81 81 -81 53 52 52 53 -80 80
-81 81 36 60 c20 32 45 91 56 129 11 39 22 76 25 83 2 9 34 12 114 12 l110 0
0 75 0 75 -115 0 c-92 0 -115 3 -115 14 0 33 -42 146 -75 200 l-36 60 81 81
80 80 -52 53 -53 52 -82 -82 c-77 -77 -83 -81 -100 -66 -28 26 -142 76 -200
89 l-53 12 0 114 0 113 -75 0 -75 0 0 -110z m216 -292 c111 -40 189 -113 241
-224 26 -54 28 -69 28 -174 0 -103 -3 -121 -27 -172 -15 -32 -48 -80 -73 -107
-98 -105 -203 -147 -343 -138 -157 9 -286 99 -355 245 -30 63 -32 74 -32 177
0 104 2 114 32 176 97 198 326 292 529 217z"
        />
        <path
          d="M1659 3607 c-61 -17 -131 -59 -165 -99 -111 -133 -105 -325 15 -445
64 -63 121 -87 218 -91 72 -4 89 -1 140 23 76 34 135 93 168 167 24 53 27 70
23 141 -4 97 -28 155 -91 218 -78 79 -206 115 -308 86z m161 -162 c117 -60
120 -236 5 -303 -50 -29 -140 -27 -182 4 -87 65 -103 175 -37 254 55 65 139
82 214 45z"
        />
        <path d="M2270 2820 l0 -80 75 0 75 0 0 80 0 80 -75 0 -75 0 0 -80z" />
        <path
          d="M4174 2686 c-3 -7 -4 -42 -2 -77 l3 -64 73 -3 72 -3 0 81 0 80 -70 0
c-50 0 -72 -4 -76 -14z"
        />
        <path
          d="M2060 2378 l0 -113 -83 -26 c-46 -15 -107 -41 -136 -59 l-53 -33 -82
82 -81 81 -53 -52 -52 -53 80 -80 81 -81 -41 -70 c-22 -39 -48 -102 -58 -142
l-17 -72 -112 0 -113 0 0 -75 0 -75 115 0 c92 0 115 -3 115 -14 0 -33 42 -146
75 -200 l36 -60 -81 -81 -80 -80 52 -53 53 -52 80 80 81 81 60 -36 c54 -33
167 -75 200 -75 11 0 14 -23 14 -115 l0 -115 80 0 80 0 0 115 c0 92 3 115 14
115 33 0 146 42 200 75 l60 36 81 -81 80 -80 53 52 52 53 -81 81 -82 82 22 31
c30 41 66 126 81 189 l12 52 114 0 114 0 0 75 0 75 -115 0 c-98 0 -115 2 -115
16 0 31 -50 164 -81 214 l-32 52 82 82 81 81 -52 53 -53 52 -81 -81 -80 -80
-75 40 c-41 22 -102 48 -136 57 l-63 17 0 114 0 113 -80 0 -80 0 0 -112z m251
-305 c156 -73 249 -215 249 -381 0 -126 -42 -223 -135 -311 -206 -195 -540
-131 -667 126 -32 65 -33 72 -33 183 0 104 3 121 27 172 49 105 149 194 258
230 88 29 216 21 301 -19z"
        />
        <path
          d="M3805 2309 c-99 -13 -195 -81 -243 -174 -35 -69 -42 -182 -16 -256
25 -70 100 -151 172 -186 47 -23 70 -28 132 -28 132 1 234 65 292 183 62 126
38 272 -61 371 -72 72 -169 104 -276 90z m124 -164 c127 -65 126 -245 -1 -311
-39 -20 -118 -20 -157 0 -82 43 -116 160 -69 241 43 74 154 108 227 70z"
        />
        <path d="M3370 1315 l0 -75 75 0 75 0 0 75 0 75 -75 0 -75 0 0 -75z" />
      </g>
    </svg>
  );
}

export default SvgComponent;
