import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={43.336}
      height={32.531}
      viewBox="0 0 43.336 32.531"
      {...props}
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill="#000000"
        stroke="none"
      >
        <path
          d="M2365 5114 c-434 -45 -801 -162 -1128 -363 -37 -22 -72 -41 -78 -41
-6 0 -25 11 -41 26 -75 62 -201 104 -315 104 -287 0 -523 -236 -523 -523 0
-114 42 -240 104 -315 15 -16 26 -35 26 -41 0 -6 -19 -41 -41 -78 -173 -283
-290 -610 -346 -973 -25 -161 -25 -539 0 -700 91 -587 331 -1065 738 -1467 63
-62 155 -145 204 -185 642 -513 1493 -684 2290 -461 929 260 1646 1043 1819
1987 156 845 -103 1682 -705 2285 -398 397 -880 639 -1449 726 -101 15 -468
28 -555 19z m530 -168 c760 -113 1408 -561 1778 -1231 139 -253 237 -552 278
-855 17 -122 17 -477 0 -600 -149 -1100 -991 -1942 -2091 -2091 -123 -17 -478
-17 -600 0 -942 129 -1705 764 -1994 1658 -179 557 -147 1170 91 1708 45 102
130 262 161 302 l17 23 62 -25 c35 -14 98 -31 140 -37 263 -38 523 147 578
409 8 36 15 66 17 68 16 14 189 121 248 153 93 51 266 121 386 156 405 119
853 110 1258 -25 1132 -376 1727 -1618 1310 -2736 -211 -567 -669 -1025 -1238
-1238 -773 -288 -1643 -98 -2226 487 -229 229 -402 506 -505 811 -176 520
-146 1059 87 1574 56 123 59 134 46 160 -29 61 -105 56 -140 -10 -180 -341
-269 -737 -255 -1122 23 -591 244 -1102 657 -1516 258 -259 543 -438 877 -549
984 -328 2038 36 2612 901 278 419 410 949 360 1455 -93 962 -792 1763 -1730
1984 -142 33 -275 50 -444 57 -433 16 -869 -95 -1234 -316 -46 -28 -85 -50
-86 -49 -2 2 -14 32 -29 68 l-26 65 23 17 c40 31 200 116 302 161 232 103 495
172 755 197 118 12 435 4 555 -14z m-1943 -289 c202 -92 280 -324 176 -520
-52 -97 -143 -162 -260 -186 -158 -33 -334 59 -404 211 -26 56 -29 74 -29 158
1 84 4 102 30 155 48 99 145 179 249 206 61 16 177 4 238 -24z"
        />
        <path
          d="M2350 4359 c-231 -26 -500 -116 -699 -234 -327 -193 -597 -495 -746
-834 -95 -214 -142 -421 -152 -661 -26 -666 309 -1281 890 -1630 361 -216 822
-298 1245 -220 655 120 1206 610 1401 1245 237 776 -59 1597 -739 2049 -169
113 -404 212 -601 254 -185 40 -414 52 -599 31z m468 -160 c34 -6 62 -15 62
-21 0 -22 -107 -120 -159 -145 -50 -25 -67 -28 -161 -28 -94 0 -111 3 -161 28
-52 25 -159 123 -159 145 0 9 70 24 170 36 61 7 343 -3 408 -15z m-713 -96
c32 -61 143 -164 215 -201 67 -33 177 -62 240 -62 105 0 240 50 331 121 45 36
131 140 141 171 6 17 95 -11 216 -68 112 -52 193 -100 286 -167 l68 -50 -61
-28 c-83 -38 -161 -114 -197 -192 -26 -56 -29 -74 -32 -189 l-4 -128 -202 0
c-265 0 -248 14 -244 -208 l3 -161 -445 -3 c-436 -3 -446 -3 -486 -25 -88 -47
-129 -123 -122 -224 7 -93 74 -173 167 -199 20 -5 122 -10 228 -10 l191 0 -31
-70 -32 -70 -706 0 c-596 0 -708 2 -712 14 -3 8 -9 73 -13 145 l-7 131 159 0
159 0 35 -47 c110 -149 326 -124 397 44 10 25 13 114 13 402 l0 369 54 26
c214 105 232 389 33 523 l-47 32 67 35 c38 20 106 52 153 72 47 19 94 39 105
44 41 18 60 11 80 -27z m-417 -306 c35 -35 37 -41 37 -102 0 -59 -2 -66 -34
-99 -22 -23 -48 -38 -70 -42 -54 -9 -91 -25 -101 -45 -6 -10 -10 -180 -10
-417 0 -389 -1 -400 -21 -426 -28 -36 -80 -36 -108 0 -12 15 -21 35 -21 45 0
69 -32 79 -256 79 l-186 0 7 48 c23 157 102 380 186 527 82 144 156 241 277
363 l112 114 75 -4 c71 -3 77 -5 113 -41z m2145 -176 c134 -163 239 -352 302
-544 19 -60 35 -115 35 -123 0 -12 -79 -14 -559 -14 l-560 0 -20 26 c-17 22
-21 41 -21 110 l0 84 199 0 c264 0 244 -16 251 207 5 162 6 170 33 216 19 33
44 56 80 77 45 26 62 30 128 30 l75 0 57 -69z m381 -918 c25 -295 -30 -590
-159 -859 l-54 -111 -137 -6 c-75 -4 -413 -7 -751 -7 -671 0 -640 3 -706 -59
-91 -84 -91 -238 0 -322 71 -66 43 -63 645 -69 l547 -5 -87 -63 c-101 -72
-311 -179 -427 -216 -420 -135 -839 -110 -1232 74 -335 157 -613 430 -783 769
-41 83 -120 300 -120 333 0 16 69 18 1133 20 l1132 3 40 22 c84 45 125 112
125 203 0 90 -42 160 -122 201 -35 18 -66 19 -620 19 -344 0 -597 4 -617 10
-60 17 -80 76 -41 118 l21 22 1103 0 1103 0 7 -77z m-1004 -243 c11 -11 20
-33 20 -50 0 -17 -9 -39 -20 -50 -19 -19 -33 -20 -365 -20 l-346 0 15 28 c8
15 27 39 43 54 57 54 75 57 366 58 254 0 268 -1 287 -20z m-418 -960 l26 -70
-144 0 c-131 0 -146 2 -164 20 -11 11 -20 33 -20 50 0 17 9 39 20 50 18 18 33
20 138 20 l117 0 27 -70z m448 65 c0 -20 -32 -75 -58 -99 -39 -37 -112 -46
-163 -22 -34 16 -71 62 -84 104 l-6 22 155 0 c86 0 156 -2 156 -5z m633 -9
c-4 -8 -30 -40 -56 -70 l-48 -56 -208 0 -209 0 26 70 27 70 238 0 c204 0 237
-2 230 -14z"
        />
        <path
          d="M2954 3735 c-25 -25 -26 -30 -22 -131 3 -97 5 -107 27 -125 30 -24
72 -24 102 0 23 18 24 26 24 131 0 105 -1 113 -24 131 -33 27 -77 24 -107 -6z"
        />
      </g>
    </svg>
  );
}

export default SvgComponent;
