import * as React from "react";

function SvgComponent(props) {
  return (
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="60pt" height="80pt" viewBox="0 0 2000.000000 2000.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,2000.000000) scale(0.100000,-0.100000)"
fill="#000000" stroke="none">
<path d="M9270 18636 c-324 -58 -559 -183 -787 -419 -259 -270 -393 -586 -393
-932 0 -142 17 -255 59 -382 63 -193 142 -324 418 -695 207 -279 295 -425 359
-596 33 -85 31 -128 -7 -185 -40 -61 -46 -103 -19 -145 42 -67 40 -67 580 -67
517 0 534 1 579 49 13 13 25 41 27 63 4 31 -1 47 -30 93 -44 67 -47 120 -12
212 61 164 145 300 381 618 300 405 372 528 422 717 35 133 43 373 18 525 -47
286 -174 526 -397 748 -207 206 -398 313 -668 377 -131 31 -408 41 -530 19z
m495 -300 c198 -50 426 -189 551 -332 182 -211 275 -451 277 -719 1 -283 -65
-433 -375 -850 -244 -329 -309 -425 -364 -535 -47 -95 -95 -240 -114 -342
l-12 -68 -242 0 -243 0 -17 83 c-56 264 -148 439 -427 812 -222 297 -277 377
-329 486 -126 265 -130 544 -11 819 146 338 469 596 834 666 116 22 348 12
472 -20z"/>
<path d="M9161 16794 c-65 -55 -37 -115 127 -271 l107 -101 5 -245 c5 -243 5
-246 29 -271 33 -35 79 -35 112 0 24 26 24 26 24 258 0 268 -6 248 106 347
107 96 169 174 169 214 0 50 -27 77 -86 82 -54 6 -52 7 -208 -131 l-67 -59
-37 34 c-174 163 -182 169 -216 169 -22 0 -45 -9 -65 -26z"/>
<path d="M9104 15110 c-28 -4 -59 -12 -70 -18 -40 -21 -76 -70 -81 -109 -5
-35 -2 -42 26 -66 60 -50 83 -52 519 -49 274 2 416 7 444 15 53 16 81 53 81
108 0 35 -6 48 -31 70 -55 50 -80 52 -472 55 -201 1 -388 -1 -416 -6z"/>
<path d="M6330 14460 c-28 -28 -27 -90 1 -116 19 -17 41 -19 215 -22 l194 -4
0 -4379 0 -4379 -205 0 -204 0 -36 -31 c-45 -40 -45 -68 0 -108 l36 -31 1005
0 1006 0 29 29 c38 38 37 71 -3 110 l-32 31 -178 0 -178 0 0 4379 0 4379 196
4 c192 3 196 3 215 27 26 32 25 82 -4 109 l-23 22 -1007 0 c-994 0 -1007 0
-1027 -20z"/>
<path d="M8534 14472 c-36 -24 -44 -88 -15 -123 19 -24 23 -24 215 -27 l196
-4 0 -4379 0 -4379 -206 0 -206 0 -34 -34 c-19 -19 -34 -42 -34 -51 0 -9 15
-32 34 -51 l34 -34 1005 0 1005 0 31 26 c42 35 42 76 0 115 l-31 29 -179 0
-179 0 0 4379 0 4379 195 4 c179 3 196 5 214 23 31 32 31 85 1 115 -20 20 -33
20 -1027 20 -555 0 -1013 -4 -1019 -8z"/>
<path d="M10669 14451 c-38 -39 -37 -62 6 -100 l36 -31 184 0 185 0 0 -4380 0
-4380 -182 0 c-193 0 -251 -9 -268 -40 -18 -34 -12 -75 16 -101 l26 -24 1030
0 1030 0 19 24 c24 30 24 82 0 112 -19 24 -23 24 -225 27 l-206 3 0 2179 0
2180 48 -6 c361 -46 609 -441 708 -1124 13 -90 17 -215 20 -570 3 -250 7 -470
10 -488 3 -23 15 -39 36 -52 32 -19 51 -17 81 8 16 14 17 137 17 2311 0 2062
-2 2299 -16 2319 -20 29 -74 30 -100 1 -18 -20 -19 -47 -25 -428 -8 -487 -27
-681 -95 -960 -119 -488 -357 -804 -637 -846 l-47 -7 0 2122 0 2122 238 -5
c255 -5 317 -14 417 -63 206 -101 340 -324 513 -854 166 -508 298 -1091 361
-1590 18 -144 25 -216 50 -577 5 -72 14 -141 20 -152 6 -10 25 -22 41 -26 41
-9 75 14 84 56 3 17 6 782 6 1700 l0 1669 -1676 0 -1676 0 -29 -29z"/>
<path d="M6300 4969 l0 -190 38 3 37 3 3 188 2 187 -40 0 -40 0 0 -191z"/>
<path d="M7820 5130 c0 -28 3 -30 35 -30 32 0 35 2 35 30 0 28 -3 30 -35 30
-32 0 -35 -2 -35 -30z"/>
<path d="M9510 5095 l0 -66 -30 16 c-83 43 -160 -18 -160 -127 0 -60 27 -109
73 -131 37 -18 38 -18 77 1 31 15 40 16 44 6 3 -8 19 -14 36 -14 l30 0 0 190
0 190 -35 0 -35 0 0 -65z m-16 -117 c30 -42 14 -131 -26 -143 -46 -15 -78 21
-78 88 0 46 25 77 60 77 18 0 34 -8 44 -22z"/>
<path d="M9790 4970 l0 -190 40 0 40 0 0 190 0 190 -40 0 -40 0 0 -190z"/>
<path d="M11620 5130 c0 -28 3 -30 35 -30 32 0 35 2 35 30 0 28 -3 30 -35 30
-32 0 -35 -2 -35 -30z"/>
<path d="M12540 4970 l0 -190 40 0 40 0 0 80 0 80 75 0 75 0 0 35 0 35 -75 0
-75 0 0 45 0 45 90 0 90 0 0 30 0 30 -130 0 -130 0 0 -190z"/>
<path d="M13140 5130 c0 -28 3 -30 35 -30 32 0 35 2 35 30 0 28 -3 30 -35 30
-32 0 -35 -2 -35 -30z"/>
<path d="M7683 5129 c-22 -14 -33 -29 -33 -45 0 -15 -6 -24 -15 -24 -10 0 -15
-10 -15 -30 0 -20 5 -30 15 -30 12 0 15 -16 15 -86 0 -47 5 -94 11 -105 14
-26 52 -40 89 -33 25 5 30 11 30 35 0 23 -5 28 -27 31 -28 3 -28 4 -31 80 l-3
77 28 3 c22 2 28 8 28 28 0 20 -6 26 -27 28 -24 3 -27 8 -30 47 l-3 44 -32
-20z"/>
<path d="M11493 5130 c-23 -13 -33 -27 -33 -44 0 -15 -7 -26 -20 -29 -12 -3
-20 -14 -20 -27 0 -13 8 -24 20 -27 18 -5 20 -14 20 -93 0 -106 15 -134 74
-134 43 0 60 14 51 43 -4 14 -15 21 -31 21 -23 0 -24 2 -24 80 0 79 0 80 25
80 21 0 25 5 25 30 0 25 -4 30 -24 30 -22 0 -25 5 -28 45 l-3 45 -32 -20z"/>
<path d="M6440 4920 l0 -140 35 0 35 0 0 83 c0 100 13 131 58 135 42 4 52 -21
52 -134 l0 -84 36 0 36 0 -4 116 c-3 113 -4 116 -31 140 -32 28 -75 31 -117 9
-27 -14 -30 -14 -30 0 0 11 -10 15 -35 15 l-35 0 0 -140z"/>
<path d="M6730 5054 c0 -4 23 -67 52 -141 51 -131 52 -133 82 -133 33 0 37 8
132 263 6 16 2 18 -31 15 l-38 -3 -30 -90 -30 -89 -32 89 c-32 89 -33 90 -69
93 -20 2 -36 0 -36 -4z"/>
<path d="M7072 5026 c-36 -34 -37 -36 -37 -109 0 -66 3 -79 24 -101 50 -54
148 -53 191 1 27 34 26 39 -12 47 -24 5 -34 2 -45 -14 -26 -37 -93 -13 -93 33
0 15 13 17 91 17 l92 0 -7 41 c-9 56 -21 76 -59 99 -51 31 -101 26 -145 -14z
m122 -48 c24 -34 20 -38 -39 -38 -57 0 -63 6 -45 41 15 27 64 25 84 -3z"/>
<path d="M7330 4920 l0 -140 35 0 35 0 0 88 c0 106 14 135 63 130 l32 -3 5
-105 5 -105 35 0 35 0 0 113 c0 112 0 114 -28 138 -32 28 -75 31 -117 9 -25
-13 -29 -13 -34 0 -3 9 -18 15 -36 15 l-30 0 0 -140z"/>
<path d="M7820 4920 l0 -140 35 0 35 0 0 140 0 140 -35 0 -35 0 0 -140z"/>
<path d="M8009 5037 c-42 -28 -64 -70 -63 -122 2 -84 57 -138 142 -138 135 0
182 200 61 265 -46 25 -99 23 -140 -5z m113 -54 c26 -23 25 -106 -2 -133 -45
-45 -100 -9 -100 67 0 54 20 83 58 83 14 0 34 -7 44 -17z"/>
<path d="M8274 5047 c-2 -7 -3 -69 -2 -138 l3 -124 35 0 35 0 3 94 c1 52 8
101 14 108 13 16 60 17 76 1 8 -8 12 -47 12 -110 l0 -98 36 0 36 0 -4 120 c-3
136 -9 148 -77 157 -34 5 -48 1 -72 -16 -29 -21 -29 -21 -29 -1 0 16 -7 20
-30 20 -17 0 -33 -6 -36 -13z"/>
<path d="M8775 5048 c-24 -14 -44 -39 -45 -55 0 -21 51 -28 62 -9 12 21 74 22
82 0 9 -24 2 -30 -58 -43 -73 -16 -100 -39 -100 -84 0 -43 15 -63 60 -77 29
-10 41 -9 74 8 31 15 40 16 40 6 0 -9 12 -14 33 -14 l33 0 -3 93 c-5 138 -10
155 -43 172 -34 18 -107 19 -135 3z m105 -162 c0 -33 -27 -58 -59 -54 -20 2
-26 8 -26 28 0 20 8 28 35 37 48 16 50 16 50 -11z"/>
<path d="M9020 4920 l0 -140 35 0 35 0 0 88 c0 106 14 135 63 130 l32 -3 5
-105 5 -105 35 0 35 0 0 113 c0 112 0 114 -28 138 -32 28 -75 31 -117 9 -25
-13 -29 -13 -34 0 -3 9 -18 15 -36 15 l-30 0 0 -140z"/>
<path d="M9934 5047 c-2 -7 -3 -69 -2 -138 l3 -124 35 0 35 0 3 94 c1 52 8
101 14 108 13 16 60 17 76 1 8 -8 12 -47 12 -110 l0 -98 36 0 36 0 -4 120 c-3
136 -9 148 -77 157 -34 5 -48 1 -72 -16 -29 -21 -29 -21 -29 -1 0 16 -7 20
-30 20 -17 0 -33 -6 -36 -13z"/>
<path d="M10250 4920 l0 -140 35 0 35 0 0 88 c0 106 14 135 63 130 l32 -3 5
-105 5 -105 35 0 35 0 0 113 c0 112 0 114 -28 138 -32 28 -75 31 -117 9 -25
-13 -29 -13 -34 0 -3 9 -18 15 -36 15 l-30 0 0 -140z"/>
<path d="M10621 5042 c-66 -33 -91 -133 -51 -199 27 -45 64 -66 114 -66 82 0
140 59 140 144 0 107 -107 171 -203 121z m103 -55 c11 -8 20 -31 23 -59 5 -40
2 -50 -20 -72 -14 -14 -33 -26 -42 -26 -9 0 -28 12 -42 26 -22 22 -25 32 -20
72 5 49 25 72 62 72 12 0 29 -6 39 -13z"/>
<path d="M10854 5043 c4 -10 26 -65 48 -123 52 -138 54 -140 87 -140 27 0 30
6 80 133 28 73 51 137 51 141 0 4 -17 6 -37 4 l-37 -3 -28 -85 c-15 -47 -28
-87 -28 -90 -1 -3 -16 36 -33 85 l-32 90 -39 3 c-34 3 -38 1 -32 -15z"/>
<path d="M11190 5032 c-37 -37 -38 -50 -3 -58 19 -5 30 -2 39 10 18 25 79 18
79 -9 0 -16 -13 -23 -64 -36 -70 -19 -91 -37 -91 -82 0 -42 16 -64 55 -77 30
-10 41 -9 75 8 31 15 40 16 44 6 3 -8 20 -14 37 -14 28 0 31 3 25 23 -3 12 -6
63 -6 113 0 119 -11 136 -98 142 -58 4 -63 3 -92 -26z m120 -145 c0 -37 -22
-59 -55 -55 -24 2 -30 8 -30 28 0 20 8 28 35 37 48 16 50 16 50 -10z"/>
<path d="M11620 4920 l0 -140 35 0 35 0 0 140 0 140 -35 0 -35 0 0 -140z"/>
<path d="M11834 5051 c-32 -14 -73 -68 -80 -107 -14 -75 25 -140 99 -164 33
-10 45 -9 83 5 139 53 113 261 -33 271 -26 2 -57 0 -69 -5z m100 -73 c9 -12
16 -41 16 -64 0 -34 -5 -47 -26 -63 -33 -26 -45 -26 -75 -2 -31 25 -34 98 -5
130 25 28 70 27 90 -1z"/>
<path d="M12080 4920 l0 -140 35 0 35 0 0 83 c0 100 13 131 58 135 42 4 52
-21 52 -134 l0 -84 36 0 36 0 -4 116 c-3 113 -4 116 -31 140 -32 28 -75 31
-117 9 -27 -14 -30 -14 -30 0 0 11 -10 15 -35 15 l-35 0 0 -140z"/>
<path d="M12880 5032 c-37 -37 -38 -50 -3 -58 19 -5 30 -2 39 10 18 25 79 18
79 -9 0 -16 -13 -23 -64 -36 -70 -19 -91 -37 -91 -82 0 -42 16 -64 55 -77 30
-10 41 -9 75 8 31 15 40 16 44 6 3 -8 20 -14 37 -14 28 0 31 3 25 23 -3 12 -6
63 -6 113 0 119 -11 136 -98 142 -58 4 -63 3 -92 -26z m120 -145 c0 -37 -22
-59 -55 -55 -24 2 -30 8 -30 28 0 20 8 28 35 37 48 16 50 16 50 -10z"/>
<path d="M13140 4920 l0 -140 35 0 35 0 0 140 0 140 -35 0 -35 0 0 -140z"/>
<path d="M13280 4920 l0 -140 34 0 33 0 6 88 c6 112 11 122 52 122 27 0 34 5
39 25 4 14 4 30 1 35 -10 16 -57 11 -77 -7 -17 -15 -18 -15 -18 0 0 13 -9 17
-35 17 l-35 0 0 -140z"/>
</g>
</svg>
  );
}

export default SvgComponent;
