import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={39.581}
      height={39.58}
      viewBox="0 0 39.581 39.58"
      {...props}
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill="#000000"
        stroke="none"
      >
        <path
          d="M2465 4954 c-291 -20 -508 -76 -750 -195 -487 -240 -808 -666 -896
-1189 -17 -99 -17 -380 0 -500 67 -488 271 -1006 569 -1448 259 -382 328 -508
356 -646 l13 -61 -38 -43 c-64 -73 -75 -178 -27 -264 20 -35 20 -39 5 -55 -29
-33 -57 -107 -57 -153 0 -54 35 -134 76 -172 16 -15 49 -36 74 -47 44 -20 62
-21 770 -21 l725 0 50 24 c141 65 187 234 98 354 l-24 33 22 45 c58 121 15
253 -105 317 -39 21 -54 22 -363 25 l-323 3 0 760 0 759 80 0 80 0 0 -134 c0
-131 1 -134 26 -160 26 -25 29 -26 160 -26 l134 0 0 -49 c0 -45 -2 -50 -30
-61 -61 -25 -117 -110 -127 -190 -15 -129 104 -260 237 -260 133 0 252 131
237 260 -10 80 -66 165 -127 190 l-30 12 0 103 c0 97 -1 104 -26 129 -26 25
-29 26 -160 26 l-134 0 0 80 0 80 174 0 173 0 27 26 c24 25 26 33 26 120 l0
94 249 0 249 0 12 -30 c14 -33 64 -81 110 -105 135 -70 308 25 335 183 28 168
-153 318 -311 257 -56 -21 -117 -74 -134 -115 l-12 -30 -249 0 -249 0 0 160 0
160 174 0 173 0 27 26 26 27 0 182 0 183 30 12 c61 25 117 110 127 190 15 129
-104 260 -237 260 -133 0 -252 -131 -237 -260 10 -80 66 -165 127 -190 l30
-12 0 -129 0 -129 -120 0 -120 0 0 94 c0 87 -2 95 -26 120 -25 24 -33 26 -120
26 l-94 0 0 209 0 209 30 12 c61 25 117 110 127 190 15 129 -104 260 -237 260
-133 0 -252 -131 -237 -260 10 -80 66 -165 127 -190 l30 -12 0 -209 0 -209
-160 0 -160 0 0 649 0 650 -23 26 c-24 28 -60 35 -152 29z m15 -438 l0 -274
-22 -6 c-13 -3 -32 -6 -44 -6 -34 0 -64 -43 -80 -113 -14 -59 -18 -66 -44 -72
-25 -5 -35 1 -82 49 -40 41 -61 56 -81 56 -47 0 -268 -123 -304 -169 -17 -22
-16 -65 4 -128 17 -53 16 -54 -5 -75 -21 -21 -22 -22 -75 -5 -77 24 -109 22
-139 -10 -62 -68 -158 -243 -158 -290 0 -20 15 -41 56 -81 48 -47 54 -57 49
-82 -5 -25 -13 -30 -56 -39 -28 -6 -57 -11 -65 -11 -9 0 -29 -13 -45 -29 l-29
-29 0 -162 0 -162 29 -29 c16 -16 36 -29 45 -29 8 0 37 -5 65 -11 43 -9 51
-14 56 -39 5 -25 -1 -35 -49 -82 -41 -40 -56 -61 -56 -81 0 -47 123 -268 169
-304 22 -17 65 -16 128 4 53 17 54 16 75 -5 21 -21 22 -22 5 -75 -24 -77 -22
-109 11 -139 67 -62 242 -158 289 -158 20 0 41 15 81 56 47 48 57 54 82 49 24
-5 30 -13 40 -58 23 -105 35 -119 113 -132 l37 -6 0 -439 0 -440 -283 0 -283
0 -16 70 c-36 153 -115 297 -380 687 -137 203 -251 418 -336 633 -208 527
-268 984 -176 1330 119 444 459 817 901 990 171 67 387 117 521 119 l52 1 0
-274z m605 -221 c29 -28 34 -85 9 -109 -24 -25 -81 -20 -109 9 -33 32 -31 60
4 96 36 35 64 37 96 4z m640 -400 c29 -28 34 -85 9 -109 -24 -25 -81 -20 -109
9 -33 32 -31 60 4 96 36 35 64 37 96 4z m-1245 -855 c0 -302 -3 -550 -7 -550
-5 0 -30 6 -58 14 -169 45 -327 197 -386 373 -32 95 -32 231 0 326 58 173 216
327 381 373 25 7 51 13 58 13 9 1 12 -115 12 -549z m720 0 l0 -400 -280 0
-280 0 0 400 0 400 280 0 280 0 0 -400z m935 -195 c33 -32 31 -60 -4 -96 -36
-35 -64 -37 -96 -4 -29 28 -34 85 -9 109 24 25 81 20 109 -9z m-890 -950 c29
-28 34 -85 9 -109 -24 -25 -81 -20 -109 9 -33 32 -31 60 4 96 36 35 64 37 96
4z"
        />
        <path
          d="M2802 4863 c-6 -43 -10 -79 -8 -80 5 -5 147 -25 151 -21 7 6 34 142
30 146 -3 4 -143 32 -156 32 -3 0 -11 -35 -17 -77z"
        />
        <path
          d="M3119 4789 c-12 -38 -20 -72 -18 -74 2 -2 35 -15 72 -29 l68 -26 25
56 c39 89 38 92 -35 120 -35 13 -70 24 -77 24 -8 0 -22 -29 -35 -71z"
        />
        <path
          d="M3418 4662 l-37 -68 63 -37 c34 -20 66 -37 69 -37 6 0 87 115 87 124
0 5 -132 86 -141 86 -2 0 -21 -31 -41 -68z"
        />
        <path
          d="M3688 4481 l-47 -58 55 -54 54 -54 58 58 57 58 -60 55 c-33 30 -62
54 -65 53 -3 0 -26 -26 -52 -58z"
        />
        <path
          d="M3920 4253 c-30 -25 -56 -48 -58 -52 -5 -14 80 -122 92 -118 7 3 38
22 70 42 l57 36 -37 52 c-67 93 -61 92 -124 40z"
        />
        <path
          d="M4088 3974 c-75 -38 -73 -27 -29 -126 11 -27 22 -48 25 -48 16 0 146
54 144 59 -2 4 -16 40 -33 79 -16 40 -32 72 -35 71 -3 0 -35 -16 -72 -35z"
        />
        <path
          d="M4235 3677 c-22 -5 -56 -13 -76 -18 l-36 -9 12 -70 c7 -39 14 -73 17
-76 7 -6 132 6 146 15 7 4 6 33 -5 86 -8 44 -15 80 -16 81 -1 0 -20 -4 -42 -9z"
        />
        <path
          d="M4157 3273 c-2 -43 -3 -79 -2 -81 2 -2 37 -6 78 -9 l74 -6 6 33 c4
18 7 57 7 86 l0 54 -79 0 -78 0 -6 -77z"
        />
        <path
          d="M3955 2385 c-8 -19 -22 -51 -30 -72 -8 -20 -14 -37 -12 -39 6 -6 140
-64 142 -62 2 2 15 32 29 67 24 59 32 91 22 91 -2 0 -34 11 -70 25 l-67 25
-14 -35z"
        />
        <path
          d="M3808 2056 l-36 -71 70 -38 c39 -21 71 -37 73 -35 1 2 19 35 38 74
l35 70 -72 36 -73 36 -35 -72z"
        />
        <path
          d="M3651 1787 c-22 -35 -41 -66 -41 -70 0 -6 115 -87 124 -87 7 0 86
117 86 128 0 8 -16 20 -101 76 l-26 17 -42 -64z"
        />
        <path
          d="M3472 1523 c-21 -32 -42 -63 -45 -69 -8 -14 123 -100 132 -86 3 5 24
35 45 66 22 31 37 57 35 59 -21 16 -107 75 -116 81 -7 4 -26 -15 -51 -51z"
        />
        <path
          d="M3302 1243 c-51 -101 -52 -97 25 -127 38 -14 71 -26 75 -26 6 0 70
131 66 135 -2 1 -33 19 -70 39 l-67 37 -29 -58z"
        />
      </g>
    </svg>
  );
}

export default SvgComponent;
